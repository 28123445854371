:root {
  --color-grey-primary: #f0f0f0;
  --color-yellow-primary: goldenrod;   
  --color-orange-primary: darkorange;
  --color-green-primary: mediumseagreen;
}
 
html{
  font-size: 62.5%;
  font-family: Arial, Helvetica, sans-serif;
}

.App{
  display: grid;
  grid-template-columns: 350px 350px 350px;
  justify-content: center;
}

.productList{
  display: flex;
  justify-content: space-evenly;
  margin-top: 5rem;
}

.productCard{
  position: relative;
  background-color: var(--color-grey-primary);
  margin: 1rem;
  flex: 1 0 25rem;
  max-width: 30rem;
  max-height: 50rem;
  transition: 0.2s;
}

.productCard:hover{
  transform: translateY(-0.5rem);
  box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}

.productImage{
  margin-top: 2rem;
  max-width: 100%;
  height: auto;
}

.productCard__cart{
  position: absolute;
  right: 0;
  margin: 1rem;
  font-size: 2rem;
  transition: 0.2s;
  cursor: pointer;
}

.productCard__wishlist{
  position: absolute;
  right: 3rem;
  margin: 1rem;
  font-size: 2rem;
  transition: 0.2s;
  cursor: pointer;
}

.productCard__fastSelling {
  position: absolute;
  font-size: 2rem;
  left: 0;
  margin: 1rem;
  fill: var(--color-orange-primary);
}

.productCard__content {
  margin: 2rem;
}

.productName{
  font-size: 1.5rem;
}

.displayStack__1,
.displayStack__2{
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productPrice{
  font-size: 1.5rem;
  font-weight: bold;
}

.productSales,
.productTime{
  font-size: 1.1rem;
}